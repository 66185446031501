const axios = require('axios');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-XSRF-TOKEN'] = document.querySelector(`meta[name="csrf_token"]`)?.value;


window.IslandCart = window.IslandCart || {};

class API {
    version = 'v1';
    _path = [];
    constructor(path = [], methods = {}){
        this.path = `api`;
        this.path = this.version;
        if(path.length){
            this._path.push(...path);
        }
        if( methods ){
            Object.assign(this, methods);
        }
    }

    get path(){
        return '/' + this._path.join('/');
    }
    set path(part){
        this._path.push(part);
        return this.path;
    }

    get(id){
        this.path = id;
        return axios.get(this.path).then(({data}) => data);
    }
    store(data){
        return axios.post(this.path, data).then(({data}) => data);
    }
    update(id, data){
        this.path = id;
        data._method = 'put';
        return axios.post(this.path, data).then(({data}) => data);
    }
    delete(id){
        this.path = id;
        data._method = 'delete';
        return axios.post(this.path).then(({data}) => data);
    }
    query(params){
        return axios.get(this.path, {params}).then(({data}) => data);
    }
}

IslandCart.api = new API([], {
    account: () => new API(['account'], {
        addresses: () => new API(['account', 'addresses'], {
            defaults: () => new API(['account', 'addresses', 'defaults'])
        }),
        paymentOptions: () => new API(['account', 'payment-options'])
    }),
    cart: () => ({
        // the two thens are because the axios AND the api wrap each of their responses with a data key
        get: () => axios.get('/cart?ajax').then(({data}) => data).then(({data}) => data),
        add: (payload) => axios.post('/cart/add?ajax', payload).then(({data}) => data).then(({data, errors}) => ({data, errors})),
        remove: (payload) => axios.post('/cart/remove?ajax', payload).then(({data}) => data).then(({data}) => data),
    }),
    countries: () => new API(['countries']),
    products: () => new API(['products']),
});


class Cart {
    items = [];
    discounts = [];

    constructor(){
        (window.location.pathname != '/checkout') && this.refresh();
    }

    hasAPI(){
        if (!IslandCart.api) throw Error('Cannot access IslandCart API!');
    }

    refresh(){
        this.hasAPI();

        IslandCart.api.cart().get().then(data => {
            this.items = data.items;
            this.discounts = data.applied_discounts;
        })
    }

    get total() {
        let total = 0;
        total = this.items.reduce((item, agg) => agg + item.total, total);
        total = this.discounts.reduce((disc, agg) => agg - disc.discount_amount, total);
        return total
    }

    get subtotal() {
        let sub = 0;
        sub = this.items.reduce((item, agg) => agg + item.total, sub);
        return sub;
    }

    increment(item_id, num = 1){
        this.hasAPI();
        let line_item = this.items.find(item => item.id == item_id);

        return IslandCart.api.cart().add({product: line_item.product.code, quantity:num});
    }

    remove(item_id){
        this.hasAPI();
        let line_item = this.items.find(item => item.id == item_id);

        return IslandCart.api.cart().remove({product: line_item.product.code});
    }
}

IslandCart.cart = new Cart();


class Checkout {
    billing_address = {
        first_name: null,
        last_name: null,
        street: null,
        street_2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
    };
    shipping_address = {
        first_name: null,
        last_name: null,
        street: null,
        street_2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
    };
    shipping_method = {
        name: null,
        rate: null,
    };
    billing_is_shipping = true;

    get shipping_address(){
        if(this.billing_is_shipping){
            return this.billing_address;
        }
        return this.shipping_address;
    }

    toggleShipping(){
        this.billing_is_shipping = !this.billing_is_shipping;
    }

    message(message = null, status = 'light', delay = null){
        let wrapper = document.getElementById('basket-message');
        wrapper.innerHTML = message ? `<div class="alert alert-${status}">${message}</div>` : '';

        if( delay !== null ){
            setTimeout(() => {
                wrapper.firstElementChild.remove();
            }, delay)
        }
    }

    resize(){
		const height = document.body.scrollHeight;
		const width =  document.body.scrollWidth;
		window.parent.postMessage({
			height,
			width
		}, '*');
    }

    submitAsync(payload = {}){
        let data = new FormData(window.top.document.getElementById('checkout-form'));
        data.append('payload', JSON.stringify(payload));

        return axios.post('/checkout/callback', data).then(({data}) => data);
    }

    submit(formOrFormDataOrPayload){
        let main_form = window.top.document.getElementById('checkout-form');
        let payload = {};

        if(formOrFormDataOrPayload instanceof FormData){
            payload = Object.fromEntries(formOrFormDataOrPayload.entries());
        } else if(formOrFormDataOrPayload instanceof HTMLFormElement){
            payload = Object.fromEntries(new FormData(formOrFormDataOrPayload).entries());
        } else if(typeof formOrFormDataOrPayload === 'object'){
            payload = formOrFormDataOrPayload;
        } else {
            throw Error('Invalid argument passed to Checkout.submit');
        }

        for(let key in payload){
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = payload[key];
            main_form.appendChild(input);
        }

        main_form.submit();
    }
}

IslandCart.checkout = new Checkout();

// Convert a nested object to a flattened dotified object ie:
/*
    {
        cart: {
            user: {
                name: 'John Doe'
            }
            product: {
                options: ['red', 'blue']
            },
        }
    }
    =>
    
    {
        'cart.user.name': 'John Doe',
        'cart.product.options.0': 'red',
        'cart.product.options.1': 'blue',
    }
*/

IslandCart.dotify = function (dottable){
    let dotified = [];

    function recurse(node, path = []){
        for(let key in node){
            let newPath = [...path, key];
            if(node[key] && typeof node[key] === 'object'){
                recurse(node[key], newPath);
            } else if (typeof node[key] !== 'function'){
                dotified[newPath.join('.')] = node[key];
            }
        }
        return;
    }

    recurse(dottable);

    return dotified;
}

IslandCart.forms = {
    // take an input name like product[options][0] and return "product.options.0"
    dotName: name => name.replace(/\[\]/g, '*').replace(/\[/g, '.').replace(/\]/g, ''),
    // take a dotified name like product.options.0 and return product[options][0]
    htmlName: name => name.split('.').reduce((acc, part) => acc == '' ? part : acc + `[${part}]`, '').replace(/\[\*\]/g, '[]'),
    hydrate: function(context, data){
        const dotted = IslandCart.dotify(data);

        for(let key in dotted){
            let input = context.querySelector(`[name="${IslandCart.forms.htmlName(key)}"]`);
            if(input) input.value = dotted[key];
        }
    },
};